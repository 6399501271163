<template>
  <div>
    <van-pull-refresh v-model="isLoading">
       <div class="item" v-for="(item,index) in list">
         <div class="item-left">
           <div class="item-avatar">
             <img class="avatar" width="30"
                  height="30" v-lazyload :data-src="item.headUrl" />
           </div>
           <div class="nickname">
             {{item.nickName}}
           </div>
         </div>
         <van-button type="primary" size="mini" round>已订阅</van-button>
       </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import {getFaveList, myJobList,subscriptionList} from "@/api/user";
import ArticleList from "@/components/ArticleList";
import {Toast} from "vant";

export default {
  name: "subscription",
  components: {ArticleList},
  data() {
    return {
      submitType: this.$route.query.type,
      list: [],
      isLoading: false,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    getList() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      subscriptionList({
        "page": "1",
        "limit": "10"
      }).then((res) => {
        if (res.code == 0) {
          this.list =this.list.concat(res.result.records || [])
        }
      })
    }
  }
}
</script>

<style scoped>
.item{
  padding: 15px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item::after{
  position: absolute;
  content: '';
  background-color: #e5e5e5;
  display: block;
  width: 100%;
  height: 1px;
  transform: scale(1, 0.5);
  bottom: 0;
  left: 0;
}
.item .avatar{
  border-radius: 15px;
}
.item-left{
  display: flex;
  align-items: center;
}
.item .nickname{
  margin-left: 10px;
  font-weight: bold;
}
/deep/.van-button--primary{
  background-color: #aeb6bd;
  border: 1px solid #aeb6bd;
}
</style>